const initDropdowns = () => {
    window.Alpine.data('dropdown', (config = { options: [] }) => ({
        open: false,
        options: config.options,
        toggle() {
            if (this.open) {
                return this.close();
            }
            this.$refs.button.focus();
            this.open = true;
        },
        close(focusAfter) {
            if (!this.open) return;
            this.open = false;
            focusAfter && focusAfter.focus();
        },
    }));
};
export default initDropdowns;
