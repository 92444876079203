document.addEventListener("DOMContentLoaded", () => {
    const accordions = document.querySelectorAll(".accordion");

    // Restore accordion state from localStorage
    accordions.forEach(accordion => {
        const header = accordion.closest("header");
        const panel = header.nextElementSibling;
        const icon = accordion.querySelector("i.fa-solid");
        const panelId = header.dataset.id;

        if (panelId != undefined && localStorage.getItem(panelId) === "open") {
            console.log((panelId))
            panel.classList.remove("collapsed");
            panel.style.maxHeight = `${panel.scrollHeight}px`;
            icon.classList.remove("fa-chevron-down");
            icon.classList.add("fa-chevron-up");
        } else {
            panel.classList.add("collapsed");
            panel.style.maxHeight = '0';
            icon.classList.remove("fa-chevron-up");
            icon.classList.add("fa-chevron-down");
        }

        accordion.addEventListener("click", () => {
            toggleAccordion(accordion);
        });
    });

    function toggleAccordion(accordion) {
        const header = accordion.closest("header");
        const panel = header.nextElementSibling;
        const icon = accordion.querySelector("i.fa-solid");
        const panelId = header.dataset.id;

        if (panel.classList.contains("collapsed")) {
            panel.classList.remove("collapsed");
            panel.style.maxHeight = `${panel.scrollHeight}px`;
            icon.classList.remove("fa-chevron-down");
            icon.classList.add("fa-chevron-up");
            localStorage.setItem(panelId, "open");
        } else {
            panel.classList.add("collapsed");
            panel.style.maxHeight = '0';
            icon.classList.remove("fa-chevron-up");
            icon.classList.add("fa-chevron-down");
            localStorage.setItem(panelId, "closed");
        }
    }
});
