import './bootstrap';
import './random-password';
import './accordion';

import Alpine from 'alpinejs';
import initDropdowns from './helpers/dropdown.js';
import initPasswordShowSwitch from './helpers/fields/password.js';

window.Alpine = Alpine;

document.addEventListener('alpine:init', () => {
    if (document.querySelector('[x-data="dropdown"]')) {
        initDropdowns();
    }
    if (document.querySelector('input[type="password"]')) {
        initPasswordShowSwitch();
    }
});

Alpine.start();
