let alpha = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '!', '@'];

function rdmPassword() {
    let pw = '';
    for (var i = 0; i < 8; i++) {
        if (Math.random() < 0.33) {
            pw += '' + Math.round(Math.random() * 9.5);
        } else {
            if (Math.random() < 0.5) {
                pw += '' + alpha[Math.round(Math.random() * 23.5)];
            } else {
                pw += '' + alpha[Math.round(Math.random() * 23.5)].toUpperCase();
            }
        }
    }
    return pw;
}

$(document).on('click', '.js-gen-pswd', function() {
    $('#password, #password-confirm').removeClass('update');

    let pw = rdmPassword();
    $('.res-pw').html(pw);

    // $('#password, #password-confirm').val(pw);
    document.getElementById("password").value = pw;
    $('#password, #password-confirm').addClass('update');

    setTimeout(function() {
        $('#password, #password-confirm').removeClass('update');
    }, 1500);

});