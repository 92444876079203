const initPasswordShowSwitch = () => {
    window.Alpine.data('passwordShowSwitch', () => ({
        show_password: false,
        togglePasswordVisibility() {
            this.show_password = !this.show_password;
        },
        get inputType() {
            return this.show_password ? 'text' : 'password';
        },
    }));
};
export default initPasswordShowSwitch;
